import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import {Observable, of, switchMap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {IRequestFilterParams} from 'scam/table';
import {IAction, IHttpOptions, IHttpResponseHandlerParams, IIBricksEntity, IMetaData, IResponse} from '../interfaces';
import {openInNewTab, removeCookies} from '../utilities';
import {FieldEnum} from '../enums/field.enum';
import {AuthService} from './auth.service';
import {LayoutService} from './layout.service';
import {ISnackBarParams, NotificationService} from './notification.service';
import {KeyEnum, ValueEnum} from '../enums/key.enum';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {
  private errorSnackbar: MatSnackBarRef<TextOnlySnackBar> | null;

  constructor(
    private http: HttpClient,
    private layoutService: LayoutService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  put<T>(url: string, postData: object, options?: IHttpOptions): Observable<T> {
    const params = options?.params || null;
    const body = this.clearPostData(postData);
    const req = this.http.put<T>(url, body, {headers: options?.headers, params, withCredentials: true});
    return this.authService
      .checkAuthorization()
      .pipe(
        switchMap(authorized =>
          authorized ? this.handlePostRequest<T>(req, options?.hideSnackbar, url) : of(null as T)
        )
      );
  }

  post<T>(url: string, postData: object, options?: IHttpOptions): Observable<T> {
    const params = options?.params || null;
    const body = this.clearPostData(postData);
    const req = this.http.post<T>(url, body, {headers: options?.headers, params, withCredentials: true});
    return this.authService
      .checkAuthorization()
      .pipe(
        switchMap(authorized =>
          authorized ? this.handlePostRequest<T>(req, options?.hideSnackbar, url) : of(null as T)
        )
      );
  }

  get<T>(url: string, options?: IHttpOptions): Observable<T> {
    const headers = this.setCacheControl(options?.headers || new HttpHeaders());
    let params = options?.params || new HttpParams();
    params = this.setRequestFilterParams(params, options?.filterParams);
    const req = this.http.get<T>(url, {headers, params, withCredentials: true});
    return this.authService
      .checkAuthorization()
      .pipe(switchMap(authorized => (authorized ? this.handleGetRequest(req, options) : of(null as T))));
  }

  del<T>(url: string, options?: IHttpOptions): Observable<T> {
    const params = options?.params || null;
    const req = this.http.delete<T>(url, {headers: options?.headers, params, withCredentials: true});
    return this.authService
      .checkAuthorization()
      .pipe(switchMap(authorized => (authorized ? this.handleDelRequest(req, options) : of(null as T))));
  }

  private handlePostRequest<T>(req: Observable<T>, hideSnackbar: boolean, url?: string): Observable<T> {
    return req.pipe(
      catchError(err => this.handleError<T>(err, {postRequest: req}, 'NOTIFICATIONS.POST-ERROR')),
      switchMap(res => {
        if (!hideSnackbar) {
          this.openResponseSnackbar(res, url);
        }
        return this.handleResponse<T>({res, postRequest: req, url, hideSnackbar});
      })
    );
  }

  private handleGetRequest<T>(req: Observable<T>, options?: IHttpOptions): Observable<T> {
    return req.pipe(
      catchError(err => this.handleError<T>(err, {getRequest: req}, 'NOTIFICATIONS.GET-ERROR')),
      switchMap(res => {
        if (!options?.skipEntityShare) {
          this.shareEntityData(res);
        }
        return this.handleResponse<T>({res, getRequest: req, notificationAction: options?.notificationAction});
      })
    );
  }

  private handleDelRequest<T>(req: Observable<T>, options?: IHttpOptions): Observable<T> {
    return req.pipe(
      catchError(err => this.handleError<T>(err, {delRequest: req}, 'NOTIFICATIONS.DEL-ERROR')),
      switchMap(res => this.handleResponse<T>({res, delRequest: req, notificationAction: options?.notificationAction}))
    );
  }

  private handleError<T>(
    err: HttpErrorResponse,
    params: Partial<IHttpResponseHandlerParams<T>>,
    message: string
  ): Observable<T> {
    console.error(err);
    if (!this.errorSnackbar) {
      this.errorSnackbar = this.openSnackbar({message, isError: true});
      const sub = this.errorSnackbar.afterDismissed().subscribe(() => {
        this.errorSnackbar = null;
        sub.unsubscribe();
      });
    }
    return this.handleResponse<T>({res: err.error || err, ...params});
  }

  private handleResponse<T>(params: IHttpResponseHandlerParams<T>): Observable<T> {
    const res = params?.res || ({} as T);
    const {data, meta} = res as IResponse<unknown>;
    const isOnlyMeta = !data && !!meta;
    const message = meta?.error_message || '';

    switch (true) {
      case isOnlyMeta && (meta.user?.name === KeyEnum.Anonymous || meta.code === 401):
        // Opens snackbar with timeout to display it on top of the dialog backdrop
        setTimeout(() => this.openSnackbar({message, isError: true}), 100);
        removeCookies();
        // Keep previous implementation, can be useful
        // return this.dialogInjectorService
        //   .openAuthDialog()
        //   .pipe(switchMap(answer => this.handleDialogResponse<T>(answer, params)));
        return of(res);
      case isOnlyMeta && [400, 403, 404, 405, 422, 500, 4000].includes(meta.code):
        this.handleMeta(meta, params, message);
        return of(res);
      // TODO remove after Keycloak integration (commented on server too)
      // case isOnlyMeta && meta.code === 412:
      //   return this.dialogInjectorService
      //     .open2FADialog(this.authService.currentProfileMailAddress)
      //     .pipe(switchMap(answer => this.handleDialogResponse<T>(answer, params)));
      case !!message:
        this.openSnackbar({message, isError: true});
        throw new Error(message);
      case !res:
        throw new Error('No response');
      default:
        return of(res);
    }
  }

  private handleMeta(meta: IMetaData, params: IHttpResponseHandlerParams, message: string): void {
    if (meta.availableEmail) {
      this.notificationService.openInUseBySnackbar(meta.availableEmail);
    } else if (meta.code === 422 && meta.error_type.includes('VALIDATION_ERROR')) {
      this.openErrorActionSnackbar(params, 'NOTIFICATIONS.URL-ID-CONFIRMED');
    } else {
      this.openErrorActionSnackbar(params, message);
    }
  }

  /**
   * KEEP FUNCTION COMMENTED (on current stage it's not in use)
   * In boolean case handles provided request with proper handler so request cancelled by server can be called once again,
   * In null case just closes dialog and returns request response.
   * @param answer - dialog response
   * @param params
   * @private
   */
  // private handleDialogResponse<T>(answer: boolean | null, params: IHttpResponseHandlerParams<T>): Observable<T> {
  //   const isSubmitted = typeof answer === 'boolean';
  //   switch (true) {
  //     case isSubmitted && !!params.getRequest:
  //       return this.handleGetRequest<T>(params.getRequest);
  //     case isSubmitted && !!params.postRequest:
  //       return this.handlePostRequest<T>(params.postRequest, params.hideSnackbar, params.url);
  //     case isSubmitted && !!params.delRequest:
  //       return this.handleDelRequest<T>(params.delRequest);
  //     default:
  //       return of(params.res);
  //   }
  // }

  /**
   * Fields like log, created, modified, author should always be ignored by server.
   * @param data
   * @private
   */
  private clearPostData(data: object): object {
    if (data instanceof FormData) {
      return data;
    }
    const clear = {...data};
    [FieldEnum.Log, FieldEnum.Created, FieldEnum.Modified, FieldEnum.Author].forEach(i => delete clear[i]);
    return clear;
  }

  private setCacheControl(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    return headers;
  }

  /**
   * // typeof res?.data === 'object' && res?.data?.unid - checks removed to make EditDashboard btn available for empty data and present meta
   * @param res
   * @private
   */
  private shareEntityData(res: IResponse<IIBricksEntity>): void {
    if (res && !Array.isArray(res?.data)) {
      this.layoutService.setEntity(res);
    }
  }

  private openSnackbar(params: ISnackBarParams): MatSnackBarRef<TextOnlySnackBar> | null {
    return this.notificationService.openSnackbar(params);
  }

  private openResponseSnackbar(res: IResponse<IAction>, url?: string): void {
    const message = this.notificationService.getResponseMessage(res, url);
    const isError = message && url?.includes(ValueEnum.Actions) && !res?.data?.successCount;
    this.openSnackbar({message, isError});
  }

  private openErrorActionSnackbar(params: IHttpResponseHandlerParams, message: string): void {
    const config: MatSnackBarConfig = params?.notificationAction ? {duration: 10000} : null;
    const action = this.notificationService.translate(params?.notificationAction?.label);
    const ref = this.openSnackbar({message, isError: true, action, config});
    const sub = ref.onAction().subscribe(() => {
      sub?.unsubscribe();
      if (params?.notificationAction?.value) {
        openInNewTab(params.notificationAction.value);
      }
    });
  }

  private setRequestFilterParams(params: HttpParams, filterParams: IRequestFilterParams): HttpParams {
    if (filterParams) {
      Object.entries(filterParams).forEach(([key, value]) => {
        if (value) {
          params = params.set(key, value);
        }
      });
    }
    return params;
  }
}
